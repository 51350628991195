#chatContainer {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;
    max-width: 300px;
}

.emoji-mart {
    position: absolute;
}

#chatMessages {
    flex-grow: 100;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

#chatMessages .message:first-child {
    margin-top: 10px;
}

#chatMessages .message {
    bottom: 0;
    word-break: break-word;
}

#chatMessages .message .message-from {
    font-weight: bold;
}

#chatInputContainer {
    margin-top: 10px;
    position: relative;
}

#chatInput {
    text-align: left;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
}

#emojiButton {
    left: 0;
    height: 100%;
    padding: 5px 2px 5px 5px;
    position: absolute;
    background: transparent;
    border-right: 1px solid #34495e;
    border-radius: 0;
}

#sendButton {
    right: 0;
    height: 100%;
    padding: 5px;
    position: absolute;
    background: transparent;
}

.emoji-mart{
    width: 260px !important;
    position: absolute;
    bottom: 40px;
    left: 0;
}

.emoji-mart-bar {
    display: none;
}

button.emoji-mart-emoji:hover {
    background: none;
}

button.emoji-mart-emoji>* {
    cursor: pointer !important;
}

button.emoji-mart-emoji {
    padding: 0;
}

