#lobbyListContainer {
    height: 100%;
}

#lobbyListContainer .buttonRow {
    padding: 10px;
    border-radius: 5px;
    display: flex;
    background: var(--white);
    height: 55px;
}

#lobbyListContainer .buttonRow button {
    margin-right: 7px;
    height: 35px;
}

#lobbyListContainer .buttonRow img {
    width: auto;
    height: 100%;
}

#lobbyListContainer .buttonRow #refresh {
    margin-left: auto;
    margin-right: 0;
}

#lobbyList {
    margin-top: 10px;
    border-radius: 5px;
    background: var(--white);
    height: calc(100% - 65px) !important;
}

#lobbyList table {
    width: calc(100% - 10px);
    margin: 5px;
    border-collapse: collapse;
}

#lobbyList tr {
    border: 1px solid #aeaeae;
}

#lobbyList tr:nth-child(even) {
    background: #dfc9c9;
}

#lobbyList th {
    padding: 10px;
    text-align: start;
}

#lobbyList td {
    padding: 5px;
}

#lobbyList button {
    margin-right: 5px;
}

#lobbyListContainer .table thead {

}