 button {
    padding: 5px 10px;
    font-size: 1em;
    text-align: center;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 8px;
    background: var(--magenta);
    color: var(--white);
    transition: background 0.2s;
}

button:hover {
    background: var(--magenta-dark);
    transition: background 0.2s;
}

button:active {
    transform: translateY(2px);
}

button:disabled {
    transform: none;
    background: var(--magenta-disabled);
    cursor: default;
}

input {
    color: #34495e;
    border: 2px solid #34495e;
    border-radius: 5px;
    padding: 5px;
    font-size: 120%;
    background-color: #ecf0f1;
    text-align: center;
}

/* Slider from https://www.w3schools.com/howto/howto_js_rangeslider.asp */
.slidecontainer {
    position: relative;
    width: 100%; /* Width of the outside container */
    margin-bottom: 10px;
}

/* The slider itself */
.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--magenta);
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--magenta);
    cursor: pointer;
}

/* Toggle Switch from https://www.w3schools.com/howto/howto_css_switch.asp */
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch-slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .switch-slider {
    background-color: var(--magenta);
}

input:focus + .switch-slider {
    box-shadow: 0 0 1px var(--magenta);
}

input:checked + .switch-slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
}

/* Rounded sliders */
.switch-slider.round {
    border-radius: 34px;
}

.switch-slider.round:before {
    border-radius: 50%;
}