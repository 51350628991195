.cardContainer {

}

.Card_front {
    background-image: url('../../assets/card_front_sprite.png');
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px;
}

.Card_front.highlighted {
    background-image: url("../../assets/card_front_win_sprite.png");
}

.Card_front p {
    font-size: 1.5em;
    margin-right: 3px;
    font-weight: bold;
}

.Card_back {
    background-image: url('../../assets/card_back_sprite.png');
}
.cardContainer, .Card_front, .Card_back {
    width: 50px;
    height: 75px;
}
.Card_front, .Card_back {
    /*backface-visibility: hidden;*/
    /*position: absolute;*/
    background-size: 100%;
}
