#settingsTable {

}

#settingsTable td {
  padding: 5px;
}

#settingsTable input[type=number] {
  width: 100%;
}

#settingsTable .title {
  font-weight: bold;
  margin-right: 20px;
}

.flexrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}

.flexrow>* {
  margin: 0 10px 0 0;
}

.flexrow>*:last-child {
  margin-right: 0;
}