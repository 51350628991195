#playerScrollContainer {
  top: 70px;
  left: 70px;
  width: calc(100% - 140px) !important;
  position: absolute !important;
  height: 160px !important;
  text-align: center;
  -webkit-mask-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 1.0), rgba(0, 0, 0, 1.0), rgba(0, 0, 0, 1.0), rgba(0, 0, 0, 1.0), rgba(0, 0, 0, 1.0), rgba(0, 0, 0, 1.0), rgba(0, 0, 0, 1.0), transparent);
}

#playerContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  position: absolute;
  min-width: 100%;
  justify-content: center;
  align-items: center;
}

#playerContainer>*:first-child {
  margin-left: 70px;
}