#lobbyContainer {
    height: 100%;
    display: grid;
    grid-template-rows: 55px auto;
    grid-template-columns: 200px auto 300px;
    grid-template-areas:
        "header header header"
        "players content chat";
    justify-items: stretch;
    align-items: stretch;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

#lobbyContainer.playing {
    grid-template-areas:
        "content content chat"
        "content content chat";
}

#lobbyContainer.spectate.playing {
    grid-template-areas:
        "header header chat"
        "content content chat";
}

#playerList {
    grid-area: players;
    background-color: var(--white);
    border-radius: 5px;
    padding: 10px;
}

#lobbyContainer .buttonRow {
    grid-area: header;
    background: var(--white);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 10px;
}

#lobbyContainer .buttonRow button {
    height: 35px;
    margin-right: 7px;
}

#lobbyContainer .buttonRow p.lobbyName {
    margin-left: auto;
    margin-right: 5px;
    font-size: 1.5em;
    font-weight: bold;
}

#chatContainer {
    background: var(--white);
    border-radius: 5px;
    grid-area: chat;
    overflow-y: auto;
}

#content {
    grid-area: content;
    overflow-y: auto;
    background: var(--white);
    border-radius: 5px;
    padding: 10px;
}

@media (max-width: 600px) {
    #lobbyContainer.playing {
        grid-template-areas:
            "content content content"
            "content content content";
    }
    #chatContainer {
        display: none !important;
    }
}
