/* Colors */
:root {
    --light-green: #02714a;
    --dark-green: #023830;
    --light-brown: #6C391A;
    --dark-brown: #4F2A13;
    --grey: #1C2321;
    --magenta: #A93B52;
    --magenta-dark: #7b2b3c;
    --magenta-disabled: #a6858c;
    --blue: #3D5670;
    --white: #ffffff;
    --black: #1A1518;
}

* {
    box-sizing: border-box;
}

body {
    background: #1b1b22;
}

#app {
    height: 100vh;
    overflow: hidden;
}

#title {
    color: white;
    font-size: 2em;
    text-align: center;
    height: 50px;
    margin-top: 10px;
}

#game {
    padding: 20px;
    height: calc(100% - 60px);
    max-width: 1280px;
    margin:auto;
}

@media (max-width: 600px) {
    #title {
        display: none;
    }

    #game {
        margin: 0;
        border: 0;
        height: 100%;
        border-radius: 0;
    }
}