#playground {
    position: relative;
}

#tableContainer {
    position: relative;
    padding: 50px;
}

#table {
    border: 20px solid var(--dark-brown);
    border-radius: 200px;
    background: var(--light-green);
    height: 400px;
}